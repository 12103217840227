import classNames from "classnames";
import dayjs from "dayjs";
import {isEmpty, first, noop, isEqual, minBy, some} from "lodash";
import {Map, View, Feature, MapBrowserEvent} from "ol";
import {Attribution, ScaleLine, Zoom} from "ol/control";
import {Coordinate} from "ol/coordinate";
import {FeatureLike} from "ol/Feature";
import Point from "ol/geom/Point";
import {defaults as interactionDefaults} from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import {fromLonLat, toLonLat} from "ol/proj";
import {transformExtent} from "ol/proj";
import {Size} from "ol/size";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import {Circle as CircleStyle, Style, Icon, Text, Fill, Stroke} from "ol/style";
import {Component, createRef, RefObject, memo} from "react";
import Supercluster from "supercluster";

import classes from "./MapView.module.scss";
import config from "../config";
import type {
  Location,
  SearchResult,
  SearchResultMapCluster,
  MapCluster,
} from "../graphql/types";
import {clamp} from "../utils";

export type SelectionEvent = {
  result: SearchResult | null;
  pixel: number[] | null;
};

export type HoverEvent = {
  results: Array<SearchResult | MapCluster>;
  pixel: number[] | null;
};

export interface MapViewProps {
  center?: Location | null;
  self?: Location | null;
  initialZoom?: number | null;
  resultList: SearchResultMapCluster[];
  satelliteView?: boolean;
  disabled?: boolean;
  clustering?: boolean;
  small?: boolean;
  onBoundsChanged?(
    lowerLeftX: number,
    lowerLeftY: number,
    upperRightX: number,
    upperRightY: number,
    center: Location | null,
    zoom: number,
    mapRect: DOMRect | null
  ): void;
  onSelection?(selection: SelectionEvent | null): void;
  onHover?(event: HoverEvent): void;
}

export interface MapViewState {
  clusteredPoints: Array<
    Supercluster.ClusterFeature<any> | Supercluster.PointFeature<any>
  >;
}

// Zoom level at which the satellite view renders
const SATELLITE_VIEW_ZOOM_LEVEL = 19;

class MapView extends Component<MapViewProps, MapViewState> {
  view: View;
  map?: Map;
  mapElement: RefObject<HTMLDivElement>;

  constructor(props: MapViewProps) {
    super(props);

    this.state = {
      clusteredPoints: [],
    };
    this.mapElement = createRef();

    // Map view setup
    this.view = new View({
      minZoom: 5,
      maxZoom: 16,
    });

    if (isEmpty(props.resultList)) {
      if (props.center) {
        // Center over detected user location
        this.view.setCenter(
          fromLonLat([props.center.longitude || 0, props.center.latitude || 0])
        );
        this.view.setZoom(props.initialZoom || 8);
      } else {
        // Fallback for no location: Center over US
        this.view.setCenter(fromLonLat([-98.5795, 39.828175]));
        this.view.setZoom(props.initialZoom || 5);
      }
    } else {
      centerView({
        view: this.view,
        resultList: props.resultList,
        initial: true,
      });
    }
    this.view.on("change", this.onMapChanged.bind(this));
  }

  componentDidMount() {
    const {onSelection = noop, onHover = noop} = this.props;

    // Only initialize the map once
    if (this.map) {
      this.updateClusters();
      return;
    }

    const mapElement = this.mapElement.current;
    if (!mapElement) {
      throw new Error("Map container not found");
    }

    // Map setup
    const map = new Map({
      view: this.view,
      target: mapElement,
      controls: [
        new Attribution({collapsible: true, collapsed: true}),
        new ScaleLine({units: "imperial"}),
        new Zoom(),
      ],
      interactions: interactionDefaults({
        altShiftDragRotate: false,
        pinchRotate: false,
      }),
      layers: [
        // Mapbox satellite
        new TileLayer({
          properties: {
            type: "base:satellite",
          },
          // maxZoom: SATELLITE_VIEW_ZOOM_LEVEL,
          source: new XYZ({
            url: `https://api.mapbox.com/styles/v1/modernovation/clpvv468p00hv01ope4zv9cvq/tiles/512/{z}/{x}/{y}?access_token=${config.mapbox.token}`,
            tileSize: 512,
          }),
        }),

        // Map markers
        new VectorLayer({
          properties: {
            type: "markers",
          },
          // visible: !disabled,
          source: new VectorSource({
            features: this.state.clusteredPoints.map(clusteredPointToFeature),
          }),
          style: markerStyle,
        }),
      ],
    });

    this.map = map;

    // Mouse cursor was moved over map
    map.on("pointermove", (event: MapBrowserEvent<MouseEvent>) => {
      const features: FeatureLike[] = map.getFeaturesAtPixel(event.pixel);

      onHover({
        results: features.map(
          feature => feature.getProperties().properties?.result
        ),
        pixel: event.pixel,
      });
    });

    // Map was clicked: Feature selected
    map.on("singleclick", (event: MapBrowserEvent<MouseEvent>) => {
      const features: FeatureLike[] = map.getFeaturesAtPixel(event.pixel);

      const result: SearchResultMapCluster | void = first(
        features.map(feature => feature.getProperties().properties?.result)
      );

      onSelection({
        result: result || null,
        pixel: event.pixel,
      });
    });

    // Map was double-clicked: Zoom to feature
    map.on("dblclick", (event: MapBrowserEvent<MouseEvent>) => {
      const features: FeatureLike[] = map.getFeaturesAtPixel(event.pixel);

      const getExpansionZoom = (feature: FeatureLike): number => {
        return feature.getProperties().properties.result.expansionZoom || 0;
      };

      const zoomToFeature: FeatureLike | undefined = minBy(
        features.filter(
          feature =>
            feature.getGeometry() &&
            feature.getProperties().properties?.type === "cluster"
        ),
        feature => getExpansionZoom(feature)
      );

      if (zoomToFeature) {
        event.stopPropagation();
        this.view.animate(
          {
            zoom: getExpansionZoom(zoomToFeature),
            center: (
              zoomToFeature.getGeometry() as Point | undefined
            )?.getCoordinates(),
          },
          {duration: 300}
        );
      }
    });

    // De-select on map move
    map.on("loadstart", () => onSelection(null));
    map.on("movestart", () => onSelection(null));

    this.updateLayers();
    this.updateClusters();
    // this.triggerBoundsChanged();

    if (window) {
      window.addEventListener("resize", this.onWindowResized.bind(this));
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("resize", this.onWindowResized);
    }
  }

  /**
   * Re-centers the map when the 'center' property changes
   */
  componentDidUpdate(prevProps: MapViewProps) {
    // Map markers changed
    if (!isEqual(prevProps.resultList, this.props.resultList)) {
      this.updateClusters();
    }

    // Satellite view toggle changed
    if (prevProps.satelliteView !== this.props.satelliteView && this.map) {
      this.updateLayers();
    }

    // Center changed
    if (
      this.props.center?.latitude &&
      this.props.center?.longitude &&
      !isEqual(prevProps.center, this.props.center)
    ) {
      const nextCenter = fromLonLat([
        this.props.center.longitude,
        this.props.center.latitude,
      ]);

      const prevCenter = this.view.getCenter();
      this.view.setCenter(nextCenter);

      // Only update bounding box if coordinates change, otherwise it will throw this into an infinite loop
      if (prevCenter) {
        const prevCenterLonLat = toLonLat(prevCenter);
        const nextCenterLonLat = toLonLat(nextCenter);
        if (
          !coordsEqual(
            {
              latitude: prevCenterLonLat[1],
              longitude: prevCenterLonLat[0],
            },
            {
              latitude: nextCenterLonLat[1],
              longitude: nextCenterLonLat[0],
            }
          )
        ) {
          this.updateClusters();
          this.triggerBoundsChanged();
        }
      }
    }
  }

  onMapChanged() {
    this.updateClusters();
    this.triggerBoundsChanged();
  }

  onWindowResized() {
    this.updateClusters();
    this.triggerBoundsChanged();
  }

  updateLayers() {
    if (!this.map) {
      return;
    }

    const layers = this.map.getLayers().getArray();
    const satelliteLayer = layers.find(
      layer => layer.get("type") == "base:satellite"
    );
    const streetsLayer = layers.find(
      layer => layer.get("type") == "base:roads"
    );

    if (!satelliteLayer || !streetsLayer) {
      return;
    }

    if (this.props.satelliteView) {
      // Satellite-only
      satelliteLayer.setMinZoom(0); // Disable zoom-based visibility
      streetsLayer.setMaxZoom(100); // Disable zoom-based visibility
      streetsLayer.setVisible(false);
    } else {
      // Streets to satellite (auto)
      satelliteLayer.setMinZoom(SATELLITE_VIEW_ZOOM_LEVEL);
      streetsLayer.setMaxZoom(SATELLITE_VIEW_ZOOM_LEVEL);
      streetsLayer.setVisible(true);
    }
  }

  /**
   * Triggers a onBoundsChanged event for the given map, calculated based on the current view parameters.
   */
  triggerBoundsChanged() {
    if (!this.map) {
      return;
    }

    const zoom = this.view.getZoom();
    if (zoom === undefined) {
      return;
    }

    // Get bounding box of map
    const mapSize = this.map.getSize();
    if (!mapSize) {
      return;
    }

    const extent = getMapExtent(this.view, mapSize);

    let center: Location | null = null;
    const viewCenter = this.view.getCenter();
    if (viewCenter) {
      const [longitude, latitude] = toLonLat(viewCenter);
      center = {latitude, longitude};
    }

    if (this.props.onBoundsChanged) {
      this.props.onBoundsChanged(
        extent[0],
        extent[1],
        extent[2],
        extent[3],
        center,
        zoom,
        this.mapElement.current?.getBoundingClientRect() || null
      );
    }
  }

  /**
   * Recomputes map marker clusters
   */
  updateClusters() {
    const {resultList, clustering, disabled} = this.props;

    if (!this.map) {
      return;
    }

    const mapSize = this.map.getSize();
    if (!mapSize) {
      return;
    }

    let clusters: Array<
      Supercluster.ClusterFeature<any> | Supercluster.PointFeature<any>
    >;

    if (clustering) {
      const index = new Supercluster({
        minZoom: 0,
        maxZoom: 12,
        minPoints: 3,
        radius: 52, // Cluster radius (in pixels); Should match cluster_image_size * scale.
      });

      index.load(
        resultList
          .filter(
            (result: SearchResultMapCluster) =>
              result.location &&
              result.location.latitude &&
              result.location.longitude
          )
          .map((result: SearchResultMapCluster) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                // Values should never be null because of filter(), but TS gets confused
                result.location?.longitude || 0,
                result.location?.latitude || 0,
              ],
            },
            properties: {
              type: "cluster",
              result: result,
            },
          }))
      );

      const boundingBox = getMapExtent(this.view, mapSize);
      clusters = index.getClusters(
        [boundingBox[0], boundingBox[1], boundingBox[2], boundingBox[3]],
        this.view.getZoom() || 0
      );

      for (const cluster of clusters) {
        if (cluster.properties.type !== "cluster") {
          continue;
        }

        cluster.properties.result.expansionZoom = index.getClusterExpansionZoom(
          cluster.properties.cluster_id
        );

        cluster.properties.children = index.getChildren(
          cluster.properties.cluster_id
        );

        cluster.properties.isMatch = some(
          index.getLeaves(cluster.properties.cluster_id) || [],
          feature => !feature.properties.result?.isMatch
        );
      }
    } else {
      clusters = resultList.map(
        (
          result: SearchResultMapCluster
        ):
          | Supercluster.ClusterFeature<any>
          | Supercluster.PointFeature<any> => {
          if (result.__typename == "MapCluster") {
            // Cluster
            const cluster = result as MapCluster;
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  cluster.location?.longitude || 0,
                  cluster.location?.latitude || 0,
                ],
              },
              properties: {
                type: "cluster",
                result: cluster,
                point_count_abbreviated: cluster.name,
                point_count: cluster.count,
                isMatch: cluster.isMatch,
              },
            };
          } else {
            // Map marker
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  result.location?.longitude || 0,
                  result.location?.latitude || 0,
                ],
              },
              properties: {
                type: "marker",
                result,
              },
            };
          }
        }
      );
    }

    const layers = this.map.getLayers().getArray();
    const vectorLayer = layers.find(
      layer => layer.get("type") == "markers"
    ) as VectorLayer<VectorSource>;

    vectorLayer.setVisible(!disabled);

    const vectorSource: VectorSource | null = vectorLayer.getSource();
    if (vectorSource === null) {
      throw new Error("Map has no markers layer");
    }

    // Add own location
    if (this.props.self) {
      clusters.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            this.props.self.longitude || 0,
            this.props.self.latitude || 0,
          ],
        },
        properties: {
          type: "self",
        },
      });
    }

    vectorSource.clear();
    for (const clusteredPoint of clusters) {
      vectorSource.addFeature(clusteredPointToFeature(clusteredPoint));
    }

    // centerView({view, resultList});
  }

  // Map container
  render() {
    return (
      <div className={classes.mapView}>
        <div
          ref={this.mapElement}
          className={classNames(classes.mapContainer, {
            [classes.small]: this.props.small,
          })}
        />
      </div>
    );
  }
}

/**
 * Converts a supercluster feature to an OpenLayers feature
 *
 * @param clusteredPoint Supercluster feature
 * @return OpenLayers feature
 */
function clusteredPointToFeature(
  clusteredPoint:
    | Supercluster.ClusterFeature<any>
    | Supercluster.PointFeature<any>
) {
  return new Feature({
    geometry: new Point(fromLonLat(clusteredPoint.geometry.coordinates)),
    properties: clusteredPoint.properties,
  });
}

/**
 * Centers the map over a result or list of results
 *
 * @param options Center view options
 *   - view: Map view
 *   - resultList: List of results to center over
 *   - initial: Is this the first time the map is being centered? Skips animation.
 */
function centerView({
  view,
  resultList,
  initial = false,
}: {
  view: View;
  resultList: SearchResultMapCluster[];
  initial?: boolean;
}): void {
  const maxZoom = 20;
  let zoom = 0;
  let center: Coordinate = [0, 0];

  if (resultList.length >= 1) {
    zoom = maxZoom;
    const firstItem = resultList[0];
    if (
      firstItem.location &&
      firstItem.location.latitude &&
      firstItem.location.longitude
    ) {
      center = fromLonLat([
        firstItem.location.longitude,
        firstItem.location.latitude,
      ]);
    }
  }

  // TODO: Center over group

  const viewZoom: number | undefined = view.getZoom();
  if (initial || (viewZoom && viewZoom < maxZoom)) {
    view.setZoom(zoom);
    view.setCenter(center);
  } else {
    view.animate({zoom}, {center}, {duration: 1500});
  }
}

/**
 * Checks if two coordinates are equal
 *
 * @param a
 * @param b
 * @return Whether or not the coordinates are equal
 */
const coordsEqual = (a: Location | void, b: Location | void): boolean => {
  if (!a?.latitude || !a?.longitude || !b?.latitude || !b?.longitude) {
    return a === b;
  }

  const minDelta = 1e-10;
  const dx = Math.abs(a.longitude - b.longitude);
  const dy = Math.abs(a.latitude - b.latitude);

  return dx <= minDelta && dy <= minDelta;
};

/**
 * Gets bounding box extent for map
 *
 * @param view
 * @param mapSize
 */
function getMapExtent(view: View, mapSize: Size) {
  return transformExtent(
    view.calculateExtent(mapSize),
    "EPSG:900913", // Google Maps Global Mercator
    "EPSG:4326" // WGS-84
  ); // ll_x, ll_y, ur_x, ur_y
}

function markerStyle(feature: FeatureLike): Style {
  const properties = feature.getProperties().properties;

  if (properties.type === "self") {
    // Self
    return new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: "#3399CC",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
      }),
    });
  } else if (properties.type === "cluster") {
    // Cluster
    const url = `/images/map/map_cluster_${properties.isMatch ? "matched" : "unmatched"}.png`;
    return new Style({
      image: new Icon({
        src: url,
        anchor: [0.5, 0.5],
        scale: clamp(properties.point_count * 0.006, 0.16, 0.8),
      }),
      text: new Text({
        text: properties.point_count_abbreviated + "",
        scale: clamp(properties.point_count * 0.05, 1.1, 3),
        font: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fill: new Fill({
          color: "#ffffff",
        }),
        stroke: new Stroke({
          color: "#000000",
          width: 0.2,
        }),
      }),
    });
  } else if (properties.type === "marker") {
    // Marker

    let matchState: string;
    if (properties.result.isMatch) {
      matchState = "_matched";
    } else {
      matchState = "_unmatched";
    }

    let watchState: string;
    if (properties.result.isWatched) {
      watchState = "_watched";
    } else if (properties.result.isShared) {
      watchState = "_shared_watched";
    } else {
      watchState = "";
    }

    const url = `/images/map/map_marker${matchState}${watchState}.png`;

    return new Style({
      image: new Icon({
        src: url,
        anchor: [0.5, 1],
        scale: 0.75,
        opacity: markerOpacity(properties.result.updatedAt),
      }),
    });
  }

  throw new Error(`Unknown feature type: ${properties.type}`);
}

/**
 * Compute opacity for map marker, based on staleness of availability data
 *
 * @param updatedAtStr updatedAt timestamp
 * @return Opacity value
 */
function markerOpacity(updatedAtStr: string | void): number {
  const minOpacity = 0.6;

  if (!updatedAtStr) {
    return minOpacity;
  }

  const today = dayjs();
  const updatedAt = dayjs(updatedAtStr);
  const daysOld = today.diff(updatedAt, "day");

  return clamp(1.0 - Math.max(daysOld - 1, 0) * 0.05, minOpacity, 1.0);
}

const MemoMapView = memo(MapView);

export default MemoMapView;
